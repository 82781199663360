import React from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Carousel from "react-bootstrap/Carousel"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImgFeatureBannerSlide1 from "../images/features-banner-slide1@2x.png"
import ImgFeatureBannerSlide2 from "../images/features-banner-slide2@2x.png"
import ImgFeaturesMan from "../images/features-man.svg"
import ImgFeaturesWoman from "../images/features-woman.svg"
import ImgStep1 from "../images/step-1@2x.png"
import ImgStep2 from "../images/step-2@2x.png"
import ImgStep3_1 from "../images/step-3-1@2x.png"
import ImgStep3_2 from "../images/step-3-2@2x.png"
import ImgMollyFounder from "../images/molly-founder@2x.png"
import SubscribeForm from "../components/subscribe-form"
import Signup from "../components/signup"

const isInViewport = elem => {
  var crTop = elem.getBoundingClientRect().top
  var crBottom = elem.getBoundingClientRect().bottom
  console.log(crTop, crBottom)

  if (crTop < 100 && crBottom > 0) {
    return true
  } else {
    return false
  }
}
class IndexPage extends React.Component {
  state = {
    activeKey: "0",
  }
  constructor(props) {
    super(props)
    this.step1Ref = React.createRef()
    this.step2Ref = React.createRef()
    this.step3Ref = React.createRef()
    this.scroll = this.scroll.bind(this)
    this.onScrollListener = this.onScrollListener.bind(this)
  }

  onScrollListener() {
    const steps = document.querySelectorAll(".homepage__steps__step")
    const self = this
    steps.forEach(function (elem) {
      if (isInViewport(elem)) {
        const activeKey = elem.dataset.step
        self.setState({ activeKey })
      }
    })
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScrollListener)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollListener)
  }

  scroll(ref, key) {
    ref.current.scrollIntoView({ behavior: "smooth" })
    this.setState({ activeKey: key })
  }
  render() {
    const { activeKey } = this.state
    return (
      <Layout>
        <SEO title="Molly - Simple Applicant Tracking Software for Startups" />

        <div className="site-main" id="main" role="main">
          <section className="jumbotron bg-white text-center features__banner">
            <Container>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                  <h1 className="jumbotron__heading mb-4">
                    Simple Applicant Tracking
                    <br />
                    built for startups
                  </h1>
                  <p className="lead mt-4 text-center muted d-none d-lg-block">
                    Streamline your recruitment pipeline to get the best talent
                    <br /> for your startup, hassle-free. Let Molly do the
                    busywork <br />
                    so you can focus on what's important.
                  </p>

                  <p className="lead mt-4 text-center muted d-block d-lg-none">
                    Streamline your recruitment pipeline to get the best talent
                    for your startup, hassle-free. Let Molly do the busywork so
                    you can focus on what's important.
                  </p>
                  <Signup />
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="features__banner__screenshot mt-5">
                <Carousel controls={false}>
                  <Carousel.Item>
                    <img height="530" src={ImgFeatureBannerSlide1} />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img height="530" src={ImgFeatureBannerSlide2} />
                  </Carousel.Item>
                </Carousel>

                <div className="features__banner__people">
                  <img className="right" height="508" src={ImgFeaturesMan} />
                  <img className="left" height="539" src={ImgFeaturesWoman} />
                </div>
              </div>
            </Container>
          </section>
          <section className="homepage__steps bg-primary">
            <Container>
              <div className="row">
                <div className="col-lg-4">
                  <div className="homepage__steps__steps-container">
                    <h2 className="text-white">
                      Startup friendly Applicant Tracking to hire like the big
                      guys
                    </h2>

                    <div className="accordion mt-md-5 d-block d-lg-none">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h3 className="mb-0">
                            <button
                              className="btn btn-link text-white"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              data-step="homepage__steps__step-1"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              01&nbsp;&nbsp; Create a job opening
                            </button>
                          </h3>
                        </div>

                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#homepage__steps__accordion"
                        >
                          <div className="card-body text-white">
                            Add a job description and custom screening questions
                            to help you easily filter the best talent. Pick a
                            recruitment pipeline template or customize it to
                            your needs.
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h3 className="mb-0">
                            <button
                              className="btn btn-link text-white"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              data-step="homepage__steps__step-2"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                              onClick={e => {
                                this.scroll(this.step2Ref)
                              }}
                            >
                              02&nbsp;&nbsp; Accept candidates
                            </button>
                          </h3>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse show"
                          aria-labelledby="headingTwo"
                          data-parent="#homepage__steps__accordion"
                        >
                          <div className="card-body text-white">
                            Display your job on a great looking Careers Page
                            that matches your startup branding or embed it on
                            your own website, and start accepting applications
                            right away!
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h3 className="mb-0">
                            <button
                              className="btn btn-link text-white"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              data-step="homepage__steps__step-3"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                              onClick={() => {
                                this.scroll(this.step3Ref)
                              }}
                            >
                              03&nbsp;&nbsp; Manage them all in one place
                            </button>
                          </h3>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse  show"
                          aria-labelledby="headingThree"
                          data-parent="#homepage__steps__accordion"
                        >
                          <div className="card-body text-white">
                            Easily shortlist candidates, schedule interviews,
                            add feedback, message candidates and send job
                            offers, all without leaving the app. Keep your
                            candidates in the loop using our messaging templates
                            which you can customize to match your tone.
                          </div>
                        </div>
                      </div>
                    </div>

                    <Accordion
                      defaultActiveKey="0"
                      activeKey={activeKey}
                      className="mt-md-5 d-none d-lg-block"
                    >
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          onClick={() => {
                            this.scroll(this.step1Ref, "0")
                          }}
                        >
                          <h3 className="mb-0 btn btn-link text-white">
                            01&nbsp;&nbsp; Create a job opening
                          </h3>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="text-white">
                            Add a job description and custom screening questions
                            to help you easily filter the best talent. Pick a
                            recruitment pipeline template or customize it to
                            your needs.
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          onClick={() => {
                            this.scroll(this.step2Ref, "1")
                          }}
                        >
                          <h3 className="mb-0 btn btn-link text-white">
                            02&nbsp;&nbsp; Accept candidates
                          </h3>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body className="text-white">
                            Display your job on a great looking Careers Page
                            that matches your startup branding or embed it on
                            your own website, and start accepting applications
                            right away!
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          onClick={() => {
                            this.scroll(this.step3Ref, "2")
                          }}
                        >
                          <h3 className="mb-0 btn btn-link text-white">
                            03&nbsp;&nbsp; Manage them all in one place
                          </h3>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body className="text-white">
                            Easily shortlist candidates, schedule interviews,
                            add feedback, message candidates and send job
                            offers, all without leaving the app. Keep your
                            candidates in the loop using our messaging templates
                            which you can customize to match your tone.
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </div>
                <div className="col-lg-8 d-none d-lg-block">
                  <div
                    ref={this.step1Ref}
                    data-step="0"
                    id="homepage__steps__step-1"
                    className="homepage__steps__step homepage__steps__step-1"
                  >
                    <img src={ImgStep1} />
                  </div>
                  <div
                    ref={this.step2Ref}
                    data-step="1"
                    id="homepage__steps__step-2"
                    className="homepage__steps__step homepage__steps__step-2"
                  >
                    <img src={ImgStep2} />
                  </div>
                  <div
                    ref={this.step3Ref}
                    data-step="2"
                    id="homepage__steps__step-3"
                    className="homepage__steps__step homepage__steps__step-3"
                  >
                    <Carousel controls={false}>
                      <Carousel.Item>
                        <img src={ImgStep3_1} />
                      </Carousel.Item>
                      <Carousel.Item>
                        <img src={ImgStep3_2} />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <section className="features__grid">
            <div className="container">
              <div className="row">
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="far fa-address-card text-primary"></i>

                  <h4>Applicant Tracking</h4>
                  <p className="muted">
                    Track your candidates throughout the recruiting pipleline,
                    from the point they first apply to your job until they get
                    hired. Say <i>good bye</i> to overflowing email inboxes with
                    thousands of candidate resumes!
                  </p>
                </div>
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="far fa-calendar-check text-warning"></i>
                  <h4>Interview Scheduling</h4>
                  <p className="muted">
                    Send calendar invites to your candidates and schedule
                    interviews without leaving the dashboard. You can also sync
                    your Google Calendar.
                  </p>
                </div>
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="far fa-comments text-success"></i>

                  <h4>Candidate Messaging</h4>
                  <p className="muted">
                    Email your candidates directly from the dashboard, and see
                    all your communication with them organized into message
                    threads.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="fas fa-magic text-danger"></i>

                  <h4>Automation</h4>
                  <p className="muted">
                    Keep your candidates in the loop throughout the recruiting
                    pipeline using our auto-responder templates which you can
                    customize to match your tone.
                  </p>
                </div>
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="fas fa-code text-warning"></i>

                  <h4>Integrations</h4>
                  <p className="muted">
                    Embed job openings on your own website. We provide a code
                    snippet that you can easily add to your HTML.
                  </p>
                </div>
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="fas fa-laptop text-success"></i>

                  <h4>Branded Careers Page</h4>
                  <p className="muted">
                    Display your job openings on a great looking Careers Page
                    that matches your startup branding.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="fas fa-vial text-success"></i>

                  <h4>Skill Tests</h4>
                  <p className="muted">
                  Don't rely on CVs and interviews alone. 
                  See what candidates can really do before you make a hire by 
                  screening them with real-world skill tests 
                  {" "}
                    <span class="badge badge-pill badge-secondary">
                      Coming soon!
                    </span>
                  </p>
                </div>
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="far fa-thumbs-up text-info"></i>

                  <h4>Team Feedback</h4>
                  <p className="muted">
                    Collaborate with your team to rate or add candidate
                    feedback, and hire the right people that not only have
                    skills but also fit your company culture.{" "}
                    <span class="badge badge-pill badge-secondary">
                      Coming soon!
                    </span>
                  </p>
                </div>
                <div className="col-md-4 text-center features__grid__feature">
                  <i className="fas fa-chart-pie text-primary"></i>

                  <h4>Reporting</h4>
                  <p className="muted">
                    Get a quick summary of how your job posts are currently
                    performing. Find out the sources of your candidates to
                    optimize your targeting.{" "}
                    <span class="badge badge-pill badge-secondary">
                      Coming soon!
                    </span>
                  </p>
                </div>
                <div className="col-md-2"></div>
              </div>
            </div>
          </section>

          <section className="homepage__why pt-5 pb-5 mt-5">
            <Container>
              <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 text-center">
                  <h2>Why we are building Molly</h2>
                  <p className="mt-4 lead" style={{ opacity: ".6" }}>
                    After trying out several Applicant Tracking software in the
                    market, we felt that none of them seemed to satisfy our
                    startup needs. They were either bulky, with unnecessary
                    features of complex HR systems or too expensive to bear for
                    a bootstrapped startup like ours.
                    <br />
                    We created Molly to scratch our own itch :)
                  </p>
                  <div className="homepage__why__founder mt-4">
                    <p className="mb-0 mt-2">
                      <strong>— Team Molly</strong>
                    </p>
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
            </Container>
          </section>
        </div>

        {/* </div> */}
      </Layout>
    )
  }
}
export default IndexPage
